import {Wrapper} from './style';
import logo from '../../CrewCam_Logo.svg';
import { WebUrl } from '../../utils';

export const Footer = () => {
    return (
        <Wrapper id="footer">
            <div>
            <img src={logo} alt="Logo" style={{ height: '50px', marginRight: '100%'}} />
            </div>
            <div id="links">
                <a className='links' href={WebUrl.Privacy}>Privacy policy</a>
                <a className='links' href={WebUrl.Terms}>Terms of service</a>
                <a className='links' href={WebUrl.Support}>Help center</a>
            </div>
        </Wrapper>
    )
}