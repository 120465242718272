import {Container} from './style';
import { useDeleteAccountmodal } from "./viewModal";

export const DeleteAccount = () => {
  const { email, setEmail, handleSubmit, getOtp, otp, setOtp, isEmail, otpSent, verifyOtpAndDelete } =
    useDeleteAccountmodal();

  return (
    <Container>
    <div id="delete">
      <h1>Delete Account</h1>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            placeholder="Please Enter Your Email Id"
          />
        </div>
        {otpSent && (
          <>
          <p onClick={getOtp} className='hyperLink'>Resend otp</p>
            <div>
              <label htmlFor="email">Otp: </label>
              <input
                type="number"
                id="otp"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                required
              />
            </div>
          </>
        )}
      </form>
      <div className='button'>
      {
        otpSent ?
        (<button onClick={verifyOtpAndDelete}>Delete Account</button>)
        : 
        (<button onClick={getOtp}>Get Otp</button>)
      }
      </div>
    </div>
    </Container>
  );
};
