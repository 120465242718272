import {AppExcaptions} from '../appExcaptions';

export class ApiService {
  jsonResponse;
  async getGetApiResponse(url) {
    try {
      const requestBody = await this.createRequestBody('GET');
      const response = await fetch(url, requestBody);
      this.jsonResponse = this.returnResponse(response);
    } catch (error) {
      throw error;
    }
    return this.jsonResponse;
  }
  async getPostApiResponse(url, payload) {
    try {
      const requestBody = await this.createRequestBody('POST', payload);
      const response = await fetch(url, requestBody);
      this.jsonResponse = this.returnResponse(response);
    } catch (error) {
      throw error;
    }
    return this.jsonResponse;
  }
  async getPutApiResponse(url, payload) {
    try {
      const requestBody = await this.createRequestBody('PUT', payload);
      const response = await fetch(url, requestBody);
      this.jsonResponse = this.returnResponse(response);
    } catch (error) {
      throw error;
    }
    return this.jsonResponse;
  }
  async getDeleteApiResponse(url, payload) {
    try {
      const requestBody = await this.createRequestBody('DELETE', payload);
      const response = await fetch(url, requestBody);
      this.jsonResponse = this.returnResponse(response);
    } catch (error) {
      throw error;
    }
    return this.jsonResponse;
  }
  async createRequestBody(method, payload = {}) {

    const requestBody = {
      method,
      headers: {
        'Content-Type': 'application/json',
      },
    };

    return method === 'GET'
      ? requestBody
      : {
          ...requestBody,
          body: JSON.stringify(payload),
        };
  }

  async readFileAsBinary(filePath) {
    try {
      const binaryData = await fetch(`${filePath}`);
      return binaryData.blob();
    } catch (error) {
      throw error;
    }
  }

  async uploadToSignedUrl(
    signedUrl,
    filePath,
    fileType,
  ) {
    try {
      const fileData = await this.readFileAsBinary(filePath);
      let contentType = fileType;
      const response = await fetch(signedUrl, {
        method: 'PUT',
        body: fileData,
        headers: {
          'Content-Type': contentType,
        },
      });

      if (!response.ok) {
        throw new Error('Failed to upload file to signed URL');
      }
    } catch (error) {
      throw error;
    }
  }

  async returnResponse(response) {
    const body = await response.json();
    switch (response.status) {
      case 200:
        return body;
      case 201:
        return body;
      case 400:
        throw new AppExcaptions(body.message, 'Bad Request');
      case 404:
        throw new AppExcaptions(body.message, 'Not found');
      case 422:
        throw new AppExcaptions(body.message, 'Validation Error');
      case 500:
        throw new AppExcaptions(body.message, 'Internal server error ');
      default:
        throw new Error('Something went wrong while fetching data');
    }
  }
}
