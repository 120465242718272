import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    margin-top: 50px;
    justify-content: center;
    display: flex;
    form>div {
        display: flex;
        justify-content: space-between;
        gap: 10px
    }
    
    .button {
        margin-top: 30px;
        display: flex;
        justify-content: center;
    }
    
    input {
        width: 200px;
        height: 25px;
    }
    #delete{
        /* width: 250px; */
        padding: 25px;
        height: 250px;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    }
`;
