import './App.css';
import { DeleteAccount } from './screens/deleteAccount';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Navbar } from './components/navBar';
import { Default } from './screens/defaultScreen';
import { Footer } from './components/footer';

function App() {
  return (
    <Router>
      <div>
        <Navbar />
        <Routes>
          <Route path="/delete-account" element={<DeleteAccount />} />
          <Route path="*" element={<Default />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
