import { useEffect, useState } from "react";
import { UserRepository } from "../../repository/user.repo";

export const useDeleteAccountmodal = () => {
  const userRepository = new UserRepository();
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState('');
  const [otpSent, setSentOtp] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    // You can add validation or submit logic here
    getOtp();
  };

  const getOtp = async () => {
    try{
      await userRepository.getOtp({email});
      setSentOtp(true);
    }catch(er){
      alert('something went wrong')
    }
  }

  useEffect(()=> {
    setSentOtp(false);
  }, [email]);

  const verifyOtpAndDelete = async () => {
    try{
      let res = await userRepository.verifyAndDelete({email, otp});
      console.log({res})
      alert("Account deleted sucessfully")
    }catch(er){
      alert("Invalid otp")
    }
  }

  function isEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  return {
    email,
    setEmail,
    handleSubmit,
    isEmail,
    otp,
    setOtp,
    verifyOtpAndDelete,
    getOtp,
    otpSent,
  }
}