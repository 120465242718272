import {ApiService} from '../../data/network/apiService';
import { AppUrl } from '../../utils';

export class UserRepository {
  apiService = new ApiService();

  async getOtp(payload) {
    const url = `${AppUrl.userEndPoint}/otp`;
    return this.apiService.getPostApiResponse(url, payload);
  }

  async verifyAndDelete(payload) {
    const url = `${AppUrl.userEndPoint}/verify-delete`;
    return this.apiService.getDeleteApiResponse(url, payload);
  }
}
