import styled from "styled-components";

export const Wrapper = styled.footer`
  width: 100%;
  background-color: #0bb089;
  display: flex;
  justify-content: space-between;

  .links {
    color: white;
  }
  #links {
    padding-right: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 15px;
    align-items: center;
  }
`;
